import React from "react"
import styled from "styled-components"
import { useFooter } from "../hooks/useFooter"
import DesignTokens from "./designTokens"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { Box, Container, Flex, Grid, Text } from "@theme-ui/components"
import { Link } from "gatsby"

const Footer = params => {
  const footer = useFooter()
  const contactLinks = footer[0].contactLinks
  // console.log(contactLinks)
  return (
    <Wrapper>
      <Container>
        <Grid columns={[1, "3fr 2fr"]}>
          <Box
            sx={{
              fontSize: 1,
              textAlign: ["left", "right"],
              "*": { marginRight: 3 },
            }}
          >
            <a
              href="https://www.iubenda.com/privacy-policy/47639727"
              target="blank"
              rel="nofollow noopener"
            >
              Privacy Policy
            </a>
            <a
              href="https://www.iubenda.com/privacy-policy/47639727/cookie-policy"
              target="blank"
              rel="nofollow noopener"
            >
              Cookie Policy
            </a>
          </Box>
        </Grid>

        <Box sx={{ mt: 4 }}>
          <Text as="p" sx={{ fontSize: 1, color: "#999999" }}>
            Winter Services S.r.l. - Via Padergnone 52 - 24050 Grassobbio (BG) -
            Italy Partita IVA: 03790640167
          </Text>
        </Box>
      </Container>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  padding-top: 3rem;
  padding-bottom: 3rem;
`

const CustomOutboundLink = styled(OutboundLink)`
  color: ${DesignTokens.colors.primary[500]};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const Content = styled.div`
  & > * {
    margin-right: 2rem;
    &:last-child {
      margin-right: 0;
    }
  }
`

export default Footer
