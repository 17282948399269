import styled from "styled-components"
import DesignTokens from "./designTokens"

const Display = styled.h1`
  font-size: ${DesignTokens.fontSizes[10]};
  font-weight: ${props => props.weight || 600};
  color: ${props => props.color || "inherit"};
  text-transform: uppercase;
  line-height: 1;
  text-align: ${props => props.align || "inherit"};
  @media screen and (max-width: 425px) {
    font-size: ${DesignTokens.fontSizes[8]};
  }
  sup {
    font-size: 50%;
    vertical-align: super;
  }
`
const DisplaySmall = styled.h2`
  font-size: ${DesignTokens.fontSizes[8]};
  font-weight: ${props => props.weight || 700};
  color: ${props => props.color || "inherit"};
  text-align: ${props => props.align || "inherit"};
  text-transform: ${props => (props.uppercase ? "uppercase" : null)};
`
const Heading = styled.h3`
  font-size: ${DesignTokens.fontSizes[9]};
  font-weight: ${props => props.weight || 700};
  color: ${props => props.color || "inherit"};
  text-align: ${props => props.align || "inherit"};
  text-transform: uppercase;
  @media screen and (max-width: 767px) {
    font-size: ${DesignTokens.fontSizes[7]};
  }
`
const HeadingSmall = styled.h4`
  font-size: ${DesignTokens.fontSizes[7]};
  font-weight: ${props => props.weight || 700};
  color: ${props => props.color || "inherit"};
  text-align: ${props => props.align || "inherit"};
  text-transform: uppercase;
  line-height: 1.1;
`
const Lead = styled.h5`
  font-size: ${props => props.size || DesignTokens.fontSizes[5]};
  font-weight: ${props => props.weight || 700};
  color: ${props => props.color || "inherit"};
  text-align: ${props => props.align || "inherit"};
  text-transform: ${props => (props.uppercase ? "uppercase" : null)};
  line-height: 1.1;
`
const Body = styled.p`
  font-size: ${props => props.size || DesignTokens.fontSizes[4]};
  font-weight: ${props => props.weight || 400};
  color: ${props => props.color || "inherit"};
  text-align: ${props => props.align || "inherit"};
  line-height: 1.5;
`
const BodySmall = styled.p`
  font-size: ${DesignTokens.fontSizes[3]};
  font-weight: ${props => props.weight || 400};
  color: ${props => props.color || "inherit"};
  text-align: ${props => props.align || "inherit"};
`
const Caption = styled.p`
  font-size: ${DesignTokens.fontSizes[4]};
  font-weight: ${props => props.weight || 400};
  color: ${props => props.color || DesignTokens.colors.primary[500]};
  text-align: ${props => props.align || "inherit"};
`
const CaptionSmall = styled.p`
  font-size: ${DesignTokens.fontSizes[2]};
  font-weight: ${props => props.weight || 400};
  color: ${props => props.color || "inherit"};
  text-align: ${props => props.align || "inherit"};
`

export {
  Display,
  DisplaySmall,
  Heading,
  HeadingSmall,
  Lead,
  Body,
  BodySmall,
  Caption,
  CaptionSmall,
}
