import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import DesignTokens from "./designTokens"
import { Hidden, Visible } from "react-grid-system"

const NavLinks = ({ theme }) => {
  // console.log(theme)
  return (
    <Links theme={theme}>
      <Hidden xs sm>
        <Link to="/fuoco/" activeClassName="active">
          Fuoco
        </Link>
        <Link to="/forma/" activeClassName="active">
          Forma
        </Link>
        <Link to="/fantasia/" activeClassName="active">
          Fantasia
        </Link>
        <span>|</span>
      </Hidden>
      <Link to="/about/" activeClassName="active">
        About
      </Link>
      <Link to="/contatti/" activeClassName="active">
        Contatti
      </Link>
    </Links>
  )
}

const Links = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 100%;
  display: flex;
  align-items: center;
  span {
    color: ${props =>
      props.theme === "dark" ? "white" : DesignTokens.colors.primary[500]};
  }

  a {
    font-weight: 600;
    color: ${props =>
      props.theme === "dark" ? "white" : DesignTokens.colors.secondary[500]};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &.active {
      color: ${props =>
        props.theme === "dark" ? DesignTokens.colors.secondary[500] : DesignTokens.colors.primary[500]};
    }
  }
  & > * {
    margin-right: 1.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
`

export default NavLinks
