import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container, Row, Col } from "react-grid-system"
import logo from "../images/logo-flambe.svg"
import logoWhite from "../images/logo-flambe-white.svg"
import { Link } from "gatsby"
import NavLinks from "./navLinks"

const Header = ({ theme }) => {
  // console.log(theme)
  return (
  <Wrapper theme={theme}>
    <Container>
      <Main>
        <Link to="/">
          {theme === "dark" ? (
            <img src={logoWhite} alt="Flambé Fine Catering" />
          ) : (
            <img src={logo} alt="Flambé Fine Catering" />
          )}
        </Link>
        <NavLinks theme={theme} />
      </Main>
    </Container>
  </Wrapper>
)}
const Wrapper = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: ${props => props.theme === "dark" ? DesignTokens.colors.primary[500] : "white" };
`

const Main = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export default Header
