import { useStaticQuery, graphql } from "gatsby"

export const useFooter = () => {
  const footer = useStaticQuery(graphql`
    query FooterQuery {
      allDatoCmsFooter {
        nodes {
          contactLinks {
            url
            anchorText
            id
          }
        }
      }
    }
  `)

  return footer.allDatoCmsFooter.nodes
}
